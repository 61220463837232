import React from "react";
import * as styles from "./supplyScrap.module.scss";

import checkbox from "../../images/consumerCheckbox.png";

const SupplyScrap = () => (
  <div className={styles.container}>
    <div className={styles.wContainer}>
      <div className={styles.sectionTextContainer}>
        <h2 className={styles.mainHeading}>
          Wir können Sie mit jeglichen Altmetallsorten versorgen
        </h2>
        <div className={styles.checkBoxGroupContainer}>
          <div className={styles.pointWrapper}>
            <img
              className={styles.image}
              src={checkbox}
              alt="consumerCheckbox"
            />
            <p className={styles.para}>
              Wir haben über 10 Jahre Erfahrung mit den Anforderungen Ihrer
              Branche.
            </p>
          </div>
          <div className={styles.pointWrapper}>
            <img
              className={styles.image}
              src={checkbox}
              alt="consumerCheckbox"
            />
            <p className={styles.para}>
              Durch unser Netzwerk haben wir größeren Zugang zu verschiedenen
              Altmetallqualitäten.
            </p>
          </div>
        </div>
        <a className={styles.button} href="/altmetall-ankauf">
          Metalle finden
        </a>
      </div>
    </div>
  </div>
);

export default SupplyScrap;
