import React from "react";
import * as styles from "./processExplanation.module.scss";

import image1 from "../../images/how-works-icon1.png";
import image2 from "../../images/how-works-icon2.png";
import image3 from "../../images/how-works-icon3.png";
import image4 from "../../images/how-works-icon4.png";

const section1 = [
  {
    image: image1,
    title: "Anfrage senden",
    description:
      "Umso detaillierter Sie Ihre Materialanforderungen angeben, desto bessere Angebote für genau passende Materialen können wir in unserem Netzwerk finden",
  },
  {
    image: image2,
    title: "Effizientere Abwicklung",
    description:
      "Durch die Vereinfachung der komplexen Abläufe, mithilfe unserer digitaler Tools läuft die Abwicklung schnell und reibungslos ab",
  },
];

const section2 = [
  {
    image: image3,
    title: "Angebote erhalten",
    description:
      "Haben wir Material, das Ihrem Kaufprofil entspricht bieten wir Ihnen unsere vorhandenen Mengen an, die Sie ganz einfach annehmen oder ablehnen können",
  },
  {
    image: image4,
    title: "Persönlicher Ansprechpartner",
    description:
      "Wir bieten einen All-in-One Service vom Angebot bis zur Logistik, sowie garantierten anonymen An- und Verkauf von Altmetall",
  },
];

const ProcessExplanation = () => (
  <div className={styles.container}>
    <div className={styles.wContainer}>
      <div className={styles.sectionContainer}>
        <span className={styles.span}>So funktioniert's</span>
        <h2 className={styles.mainHeading}>
          Sagen Sie uns welche Metalle Sie suchen und wir bieten Ihnen unsere
          Mengen an
        </h2>
        <div className={styles.sectionColoumContainer}>
          <div className={styles.sectionColumn}>
            {section1.map(item => (
              <div>
                <img
                  className={styles.image}
                  src={item.image}
                  alt="how-works-icon1"
                />
                <h4 className={styles.subHeading}>{item.title}</h4>
                <p className={styles.para}>{item.description}</p>
              </div>
            ))}
          </div>
          <div className={styles.sectionColumn}>
            {section2.map(item => (
              <div key={item.title} className={styles.block}>
                <img
                  className={styles.image}
                  src={item.image}
                  alt="how-works-icon1"
                />
                <h4 className={styles.subHeading}>{item.title}</h4>
                <p className={styles.para}>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProcessExplanation;
