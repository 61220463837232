import React, { useContext } from "react";
import NavMenu from "../../components/nav-menu/NavMenu";

import Kaufen from "../../components/kaufen/Kaufen";
import BenefitSection from "../../components/benefit-section/BenefitSection";
import OpinionSlider from "../../components/opinion-slider/OpinionSlider";
import ProcessExplanation from "../../components/process-explanation/ProcessExplanation";
import SupplyScrap from "../../components/supply-scrap/SupplyScrap";
import ProcessOptimization from "../../components/process-optimization/ProcessOptimization";
import GroupLegal from "../../components/group-legal/GroupLegal";

import Footer from "../../components/main-page/sections/footer/Footer";
import styles from "./index.module.scss";
import * as actions from "../../action";
import { connect } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import DomainContext from "../../context/DomainContext";
import SEOComponent from "../../components/Shared/Seo";

class AltmetallPage extends React.Component {
  componentDidMount() {
    this.props.getProductList();
  }

  render() {
    const { domain, productList } = this.props;
    return (
      <I18nextProvider i18n={i18n} defaultNS={"default"}>
        <SEOComponent page="altmetall-kaufen" />

        <NavMenu />
        <div className={styles.breadcrumb}>
          <a href="/">Home</a>
          <span className={styles.separator}>/{" "}</span>
          <span>Altmetall-Kaufen</span>
        </div>

        <div className={styles.container}>
          <section className={styles.headerWidget} id="topPage">
            <div className={styles.hubBannerContainer} id="hubBannerContainer">
              <div className={styles.wContainer}>
                <div className={styles.formContainer}>
                  <div className={styles.mobileTop}></div>
                  <div className={styles.hubBannerFormBody}>
                    <Kaufen productList={productList} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <BenefitSection />
        <OpinionSlider />
        <ProcessExplanation />
        <SupplyScrap />
        <ProcessOptimization />
        <Footer domain={domain} />
        <GroupLegal />
      </I18nextProvider>
    );
  }
}

function mapStateToProps({ products }) {
  return {
    productList: products.productList,
  };
}
export function AltmetallWithContext(props) {
  const domain = useContext(DomainContext);

  return <AltmetallPage {...props} domain={domain} />;
}

export default connect(mapStateToProps, actions)(AltmetallWithContext);
