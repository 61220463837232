import React from "react";
import * as styles from "./benefitSection.module.scss";

import image1 from "../../images/benefits-icon-1.png";
import image2 from "../../images/benefits-icon-2.png";
import image3 from "../../images/benefits-icon-3.png";

const benefitList = [
  {
    image: image1,
    title: "Geprüfte Lieferanten und geprüftes Altmetall",
    para:
      "Wir arbeiten in unserem Netzwerk nur mit geprüften Lieferanten und geprüften Altmetallen, um die Werksqualität für die Weiterverarbeitung garantieren zu können",
  },
  {
    image: image2,
    title: "Schnelle und flexible Materialverfügbarkeit",
    para:
      "Durch rasche Qualifizierung mithilfe unserer modernen Technologien, können wir dynamisch auf Angebot und Nachfrage reagieren",
  },
  {
    image: image3,
    title: "Genau das richtige Altmetall",
    para:
      "Durch unser Netzwerk haben wir größeren Zugang zu Altmetallqualitäten und können Sie deshalb mit Material, das genau Ihrem Kaufprofil entspricht, versorgen",
  },
];

const BenefitSection = () => (
  <div className={styles.benefitSection}>
    <div className={styles.wContainer}>
      <span className={styles.schrott24}>Schrott24 Service</span>
      <h2 className={styles.mainHeading}>Benefits</h2>
      <p className={styles.para}>
        Schrott24 PRO ist unabhängig und ermöglicht einen anonymen An- und
        Verkauf von Altmetall.
      </p>
      <div className={styles.cardGroupContainer}>
        <div className={styles.benefitCardGroup}>
          {benefitList.map(item => (
            <div className={styles.benefitCard}>
              <div className={styles.orangeBorder}></div>
              <img
                className={styles.image}
                src={item.image}
                alt="benefits-icon-1"
              />
              <h4 className={styles.subHeading}>{item.title}</h4>
              <p className={styles.subPara}>{item.para}</p>
            </div>
          ))}
        </div>
        <div className={styles.blueDiagonalLine}></div>
        <div className={styles.blueBottomSquare}></div>
      </div>
    </div>
  </div>
);

export default BenefitSection;
