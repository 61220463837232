import React from "react";
import * as styles from "./processOptimization.module.scss";

import optimizeProcess from "../../images/optimizeProcess.png";

const ProcessOptimization = props => (
  <div className={styles.processOptimizationSection}>
    <div className={styles.wContainer}>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionImageContainer}>
          <img
            className={styles.image}
            alt="optimized process"
            src={optimizeProcess}
          />
        </div>
        <div className={styles.sectionTextContainer}>
          <span>Prozessoptimierung</span>
          <h2 className={styles.mainHeading}>
            Schnelle und flexible Verfügbarkeit von geprüften Altmetallen
          </h2>
          <p className={styles.para}>
            Für alle metallverarbeitenden Unternehmen (Hütten, Shredder,
            Schmelzen, Gießereien, Raffinerien), die hochwertiges Altmetall
            suchen und ihre Zulieferung sichern wollen, bieten wir
            verschiedenste Altmetallsorten und Legierungen an.
          </p>
          <p className={styles.para}>
            Unser großes Netzwerk besteht aus über 400 ausgewählten
            Schrotthändern und Entfallstellen. Dadurch haben wir größeren Zugang
            zu geprüftem und qualitativ hochwertigem Altmetallqualitäten, das
            genau auf Ihr Kaufprofil zutrifft.
          </p>
          <p className={styles.para}>
            Die moderne Technologie unserer digitalen Plattform ermöglicht eine
            rasche Qualifizierung, um eine schnelle und flexible Verfügbarkeit
            der sekundär Rohstoffe zu gewährleisten.
          </p>
          <p className={styles.para}>
            Über 10 Jahre Erfahrung im Altmetallhandel und der individuellen
            Betreuung unserer Kunden, haben uns mit den Anforderungen für die
            Umarbeitung der Metalle vertraut gemacht und sind uns daher der
            Verantwortung bewusst, Sie nur mit geprüften und hochwertigen
            Altmetall für die Weiterverarbeitung zu versorgen
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ProcessOptimization;
